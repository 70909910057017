import React from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

const Faq = () => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>
        {modal ? (
          <Link to={closeTo} className="modalcloselink">X</Link>
        ) : (
          <header>
            <hr />
          </header>
        )}

        <h2>ABOUT THIS IS HAPPENING</h2>
        <hr />

        <p> This is Happening is now accepting submissions from creators looking to share their stories from major global events on our new original program. </p>

        <p>This is Happening is a user-generated documentary series covering major events of social, cultural and global impact. The topics will range from music and pop culture, to sports, human rights and politics, and will be told first person from various points of view, by the people living through these events. Produced by the leader in user-generated content Jukin Media for cross-platform distribution, this show will explore important events from the past year; shedding new light on current events by telling in-depth, personal stories. Perspectives will seek to engage a millennial audience to encourage them to get involved and participate in the larger global community, to make it a better place than the one they inherited.</p>

        <p>Share your story with This is Happening and the world, today!</p>


      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default Faq
